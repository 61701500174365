import React from 'react';
import CommonWrapper from '../CommonWrapper';
import PageBanner from '../PageBanner';
import { Helmet } from 'react-helmet';
import WorksGallery from './WorksGallery';

const Works = () => {
  window.ga('set', 'page', '/works');
  window.ga('send', 'pageview');
  return (
    <>
      <Helmet>
        <title>Works of DJ Jay</title>
        <meta name="Description" content="Works, photos and videos of DJ Jay" />
        <meta name="Keywords" content="portfolio, video, photos, dj,party,events,confetti,music"></meta>
      </Helmet>
      <PageBanner title="My Works" />
      <CommonWrapper>
        <WorksGallery />
      </CommonWrapper>
    </>
  );
}

export default Works;