import 'whatwg-fetch';
import '@babel/polyfill';

import React, { Component } from 'react';
import Lightbox from 'react-images';
import { Link } from 'react-router-dom';

//const imageHeight = 271;//px

class WorksGallery extends Component {
  constructor() {
    super();
    this.state = {
      galleryImages: [],
      galleryVideos: [],
      lightboxIsOpen: false,
      currentImage: 0
    };

    this.updateGallery = this.updateGallery.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }
  updateGallery({ works = [], videos = [] }) {
    this.setState({ galleryImages: works });
    this.setState({ galleryVideos: videos });
  }
  componentDidMount() {
    fetch('./service/works.json')
      .then((response) => response.json())
      .then((data) => {
        this.updateGallery(data);
      })
      .catch((error) => {
        console.log('An error occured during fetching images', error);
      });
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  gotoImage(index) {
    this.setState({
      currentImage: index
    });
  }
  handleClickImage() {
    if (this.state.currentImage === this.state.galleryImages.length - 1) return;

    this.gotoNext();
  }

  render() {
    return (
      <div className="mx-5 my-3">
        <div className="row justify-content-center justify-content-md-start">
          {this.state.galleryVideos.map(({ title, url }, index) => (
            <div key={`video-${index}`} className="col-auto text-center">
              <iframe
                title={title}
                width="100%"
                height="225"
                src={url}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          ))}
          {this.state.galleryImages.map((image, index) => (
            <div key={index} className="col-auto text-center">
              <a href="/#" onClick={(e) => this.openLightbox(index, e)}>
                <img
                  alt={image.caption}
                  src={image.thumbnail}
                  className="img-fluid rounded m-2 border gallery-image"
                />
              </a>
              {/* <div className="text-center mb-2 image-caption" title={image.caption}>{image.caption}</div> */}
            </div>
          ))}
          <div className="col-auto d-flex align-items-center btn-view-more-works">
            <Link
              to="/portfolio-of-dj-jay-kerala"
              className="btn btn-sm btn-success"
            >
              View all works
            </Link>
          </div>
          <Lightbox
            images={this.state.galleryImages}
            currentImage={this.state.currentImage}
            backdropClosesModal={true}
            showThumbnails={true}
            isOpen={this.state.lightboxIsOpen}
            onClickImage={this.handleClickImage}
            onClickNext={this.gotoNext}
            onClickPrev={this.gotoPrevious}
            onClickThumbnail={this.gotoImage}
            onClose={this.closeLightbox}
          />
        </div>
      </div>
    );
  }
}

export default WorksGallery;
