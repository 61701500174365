import React from 'react';
import PageBanner from '../PageBanner';
import CommonWrapper from '../CommonWrapper';
import { BtnCallMe } from '../GenericUI';
import { Helmet } from 'react-helmet';
import playerImage from '../../images/dj-jay-player-pioneer.jpg';

const Expertise = () => {
  if (window.ga) {
    window.ga('set', 'page', '/expertise');
    window.ga('send', 'expertise');
  }

  return (
    <>
      <Helmet>
        <title>Expertise of DJ Jay</title>
        <meta name="Description" content="Area of expertise of DJ Jay" />
        <meta
          name="Keywords"
          content="area of expertise, dj,party,events,confetti,music"
        ></meta>
      </Helmet>
      <PageBanner title="Areas of expertise" />
      <CommonWrapper>
        <section className="mb-4 text-shadow">
          <h2 className="h4">Dealing with my clients</h2>
          <p className="my-2 pl-3">
            I consider this as my greatest expertise. I deal with each of my
            clients directly. This makes for streamlined, personal communication
            and better understanding of client requirements.
          </p>
        </section>
        <section className="mb-4 text-shadow">
          <h2 className="h4">I have experience in these type of DJ music</h2>

          <div className="my-3 pl-3">
            Electronica, Country, Jazz Rap, Drum &amp; Bass, Trance, Pop, Rock,
            Hip Hop, Hindi, Kannada, Goan Trance, Pujabi, Malayalam, Telugu,
            Tamil, Classic House, Melodies, Progressive Trance
          </div>
        </section>
        <section className="mb-4 text-shadow">
          <h2 className="h4">I am a DJ and Sound Engineer</h2>

          <div className="my-3 pl-3">
            I can play music as well as work on other sound engineering stuff
            which include operation of all sound instruments.
          </div>
        </section>

        <section className="mb-4 text-shadow">
          <h2 className="h4">I have in house light and sound equipments</h2>

          <div className="my-3 pl-3">
            I can bring my own light and sound equipments, which is an added
            advantage for my clients. Clients don't need to look for other light
            and sound vendor. This also means I can give attractive perks.
          </div>
        </section>
        <section className="mb-4 text-shadow">
          <h2 className="h4">
            I have Dry Ice machine, Foam machine and Confetti equiments
          </h2>

          <div className="my-3 pl-3">
            I have all the equipments needed for an event. Please checkout my{' '}
            <a
              title="Youtube channel of DJ Jay"
              href="https://www.youtube.com/channel/UCq-KLM3wE_HgN2eGPTAnMWg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Youtube channel
            </a>{' '}
            also.
          </div>
        </section>

        <p className="my-5 text-center alert alert-warning">
          <small>
            If you cannot find what you are looking, please let me know, I will
            do it as per Your requirement. Client statisfaction is my motto.{' '}
          </small>
        </p>
        <BtnCallMe className="text-center" />
        <div className="my-3 text-center">
          <img
            alt="Pioneer play of DJ Jay, Cochin"
            className="img-fluid img-thumb rounded"
            src={playerImage}
          />
        </div>
      </CommonWrapper>
    </>
  );
};

export default Expertise;
