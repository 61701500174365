import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import imgDJJay from '../../../images/dj-jay.jpg';
import { Link } from 'react-router-dom';
import './Hero.css';

const musicTypes = ["Electronica", "Punjabi", "Funk", "Jazz", "Fusion", "Telugu", "Rap", "Drum & Bass", "Trance", "Pop", "Rock", "Malayalam", "Hip Hop", "Hindi", "Free Style", "Kannada", "Goan Trance", "Country", "Tamil", "Classic House", "Melodies", "Progressive Trance"];

const Hero = () => {
  return <>
    <div className="row mt-4 no-gutters h3">
      <div className="col-12 col-md-6 text-center text-md-right">DJ JAY plays </div>
      <div className="col-12 col-md-6 text-center text-md-left">
        <ReactTypingEffect
          text={musicTypes}
          speed={100}
          eraseDelay={1000}
          className="ml-2 type-text text-red"
        />
      </div>
    </div>
    <div className="row">
      <div className="col text-center">
        <img id="hero-img" className="img-fluid rounded-circle dj-img mt-2" alt="DJ Jay" src={imgDJJay} />
        <h1 className="h5 my-4 text-shadow">DJ from Kochi, will play anywhere in Kerala. <br /> I do DJ, I do stage effects<br /> I have global DJ experience.</h1>
        <div className="my-2 mt-3">
          <Link className="btn btn-primary mr-1" to="/contact-dj-jay-top-dj-in-cochin-kerala">Hire me</Link>{' '}
          <Link className="btn btn-success ml-1" to="/portfolio-of-dj-jay-kerala">My works</Link>
        </div>
      </div>
    </div>
  </>;
}

export default Hero;