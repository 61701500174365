import React from 'react';
import PageBanner from '../PageBanner';
import CommonWrapper from '../CommonWrapper';
import ImgDJDay from '../../images/DJ-Jay-Cochin.jpg';
import { BtnCallMe } from '../GenericUI';
import {Helmet} from 'react-helmet';

const Contact = ()=> {
        const title = <>Contact <span className="h1" role="img" aria-label="dj jay icon">&#x1f920;</span> DJ JAY</>;
        if(window.ga) {
          window.ga('set', 'page', '/contact');
          window.ga('send', 'contact');
        }

        return(
          <>
            <Helmet>
              <title>Contact DJ Jay</title>
              <meta name="description" content="Contact details of DJ Jay" />
              <meta name="keywords" content="contact, dj,party,events,confetti,music"></meta>
            </Helmet>
            <PageBanner title={title} />
            <CommonWrapper>
              <div className="row">
                <div className="col-12 col-sm-3 col-xl-3 my-2" >
                  <img src={ImgDJDay} className="rounded img-fluid" alt="DJ Jay" />
                </div>
                <div className="col-12 col-sm-6 text-shadow my-5 pl-lg-5 h5">
                  <address>
                  <strong>DJ JAY (Jayaraj PB)</strong><br />
                  55/3402, Chilavanoor<br />
                  Kadavanthra, Cochin 682020<br />
                  Kerala, India<br />
                  <abbr title="Phone">P:</abbr> + 91 97 4638 8111 <br />
                </address>
                <BtnCallMe />
                </div>
              </div>
              
            </CommonWrapper>
          </>
        );
}

export default Contact;