import React from 'react';
import PageBanner from '../PageBanner';
import CommonWrapper from '../CommonWrapper';
import { Helmet } from 'react-helmet';
import imgDJJay from '../../images/dj-jay-1.jpg';

const About = () => {
  if(window.ga) {
    window.ga('set', 'page', '/about');
    window.ga('send', 'about');
  }

  return (
    <>
      <Helmet>
        <title>About DJ Jay-DJs in Kerala</title>
        <meta name="Description" content="Who is DJ Jay and other details" />
        <meta name="Keywords" content="about dj jay, dj in kerala"></meta>
      </Helmet>
      <PageBanner title="About DJ JAY" />
      <CommonWrapper className="text-shadow">
        <span className="d-block d-md-inline text-center">
          <img src={imgDJJay} alt="DJ Jay" className="img-fluid rounded float-md-left mr-3 mb-2" width="195px" />
        </span>
        <div className="text-shadow">
          <p>DJ Jay, myself is one of the DJs in Kerala, a south India state with rich cultural heritage. I belong to Kochi, a small, beautiful South Indian city with vibrant crowd and lot of music lovers.</p>

          <p>From my childhood days I felt that I had unoccupied talent and passion for being a DJ. By instinct I started learning music and sound engineering. In a short course of time I picked up DJ-ing techniques and skills from my master and other DJ friends while constantly improving and developing my very own skills.</p>

          <p>I started my career by playing in friend’s parties and in local clubs. People appreciated my DJ-ing skills and slowly I became popular among the crowd. My skills not only include how to incorporate diverse genres and styles, but to transcend them by infusing music with pure emotion, featuring spine-tingling breakdowns and euphoric melodies at every end of the spectrum. I have played in many clubs in Kochi as well as in other states and also in fashion shows, corporate events, product and company launches.</p>

          <p>My aim is to further improve my career as a DJ by learning and experimenting new sound engineering techniques. My unique style gathered from my experiences has made me a recognized DJ among many clubbers in Kochi and around Kerala.</p>
        </div>
      </CommonWrapper>
    </>
  );
}

export default About;