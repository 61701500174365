import React from 'react';
/* import { Player } from 'video-react'; */
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Hero from './Hero';
import WorksGallery from '../Works/WorksGallery';
import "video-react/dist/video-react.css"; // import css

const Home = () => {
  if(window.ga) {
    window.ga('set', 'page', '/home');
    window.ga('send', 'pageview');
  }
  return (
    <>
      <Helmet>
        <title>DJ Jay official - DJ based in Cochin, will play anywhere in Kerala</title>
        <meta name="Description" content="Official website of DJ Jay" />
        <meta name="Keywords" content="dj,party,events,confetti,music"></meta>
      </Helmet>
      <div className="container home-page">
        <div className="row">
          <div className="col-12 col-md-6"><Hero /></div>
          <div className="col-12 col-md-6">
            <section className="mt-4 pt-2">

              <div>
                <span className="h5 text-shadow">Featured video </span>
                <Link className="small" to="/portfolio-of-dj-jay-kerala"> more</Link>
              </div>
              <div className="d-flex d-md-block justify-content-center">
                <div className="video-container">
                  <iframe title="DJ Jay playing video" width="100%" height="200" src="https://www.youtube.com/embed/_VEp8r1zwec?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>

            </section>
            {<section className="mt-4 pt-2">
              <div className="h5">My Instruments</div>
              <span className="badge badge-outline">CDJ 3000 player</span>
              <span className="badge badge-outline">DJM 900NSX 2 mixer</span>
              <span className="badge badge-outline">Apple MacBook Air</span>
            </section>}

            <section className="mt-4 pt-2">
              <span className="badge badge-info p-2">{(new Date()).getFullYear() - 2003} years</span>
              <span className="h5"> Experience in DJ-ing &amp; sound engineering </span>
            </section>

            <section className="text-shadow mt-4 pt-2">
              <h2 className="h5">Places I have visited</h2>
              <span className="badge badge-outline">Singapore</span>
              <span className="badge badge-outline">Malaysia</span>
              <span className="badge badge-outline">Hongkong</span>
              <span className="badge badge-outline">Japan</span>
              <span className="badge badge-outline">Sri Lanka</span>
              <span className="badge badge-outline">Thailand</span>
              <span className="badge badge-outline">Macau</span>
              <span className="badge badge-outline">China</span>
              <span className="badge badge-outline">Australia</span>

            </section>

          </div>
        </div>
        <div className="row">
          <div className="h5">Featured works</div>
          <WorksGallery />
        </div>
      </div>
    </>
  );
}

export default Home;
