import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './components/Home';
import Footer from './components/Footer';
import Header from './components/Header';
import Works from './components/Works';
import About from './components/About';
/* import NoMatch from './components/NoMatch'; */
import './App.css';
import Expertise from './components/Expertise';
import Contact from './components/Contact';
import ScrollToTop from 'react-router-scroll-top'

class App extends Component {
  render() {
    return (
      <BrowserRouter>  
        <ScrollToTop>
          <div className="text-light App">
            <Header />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/portfolio-of-dj-jay-kerala" exact component={Works} />
              <Route path="/about-dj-jay-kochi-kerala" exact component={About} />
              <Route path="/expertise-of-dj-jay-cochin" exact component={Expertise} />
              <Route path="/contact-dj-jay-top-dj-in-cochin-kerala" exact component={Contact} />
              <Route component={Home} />
            </Switch>
            <Footer />
          </div>      
        </ScrollToTop>   
      </BrowserRouter>
    );
  }
}

export default App;
